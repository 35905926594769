export const ar = {
    home: "الصفحة الرئيسية",
    about: "من نحن",
    portfolio: "أعمالنا",
    services: "خدماتنا",
    contact: "اتصل بنا",
    button: "تواصل معنا",
    blog: "المقالات",
    partners: "شركاؤنا",
    aboutText: "تم تأسيس شركة التصميم الحديث في أواخر عام 2017 بفريق صغير ورؤية واضحة. ساعدتنا رؤيتنا على التغلب على العديد من التحديات، والأهم من ذلك، تمكنا من تحقيق العديد من النجاحات من خلال الإيمان الراسخ بأن مفتاح نجاحنا يكمن في التزامنا بتأسيس أنفسنا كمجموعة متخصصة تقدم مشاريع إبداعية وأعمال عالية الجودة. لدينا ثقة كاملة بأن فريقنا بأكمله يشارك هذه الرؤية ويعمل على تنفيذها في جميع أنشطتنا اليومية.",

    projectsTitle: "مشاريعنا",
    project1: "مشروع نقد الأفلام",
    project2: "مشروع المجال الثقافي",
    project3: "مشروع بينالي الدرعية",
    project4: "مشروع جميلة وبثينة المسرحي، وزارة الثقافة",
    project5: "مشروع مهرجان العودة إلى الرياض",
    project6: "مشروع التراث اليونسكو وزارة الثقافة",
    project7: "مشروع رالي داكار",
    project8: "مشروع الجولة السعودية",
    project9: "مشروع الغناء بالعربية الفصحى بوزارة الثقافة",
    project10: "معرض الكتاب بالمدينة",
    project11: "وزارة السياحة",
    project12: "أرض المعارض",
    project13: "اليوم الوطني",
    project14: "مشروع الفاس",
    project15: "مشروع الفضاء",
    project16: "مشروع المراعي",
    project17: "يوم التأسيس",
    project18: "الاتحاد السعودي",
    project19: "مشروع LEAP",
    project20: "مشروع LEAP",
    project21: "محمية الملك سلمان",
    project22: "مخيمات الدرعية",
    project23: "مشروع مسك",
    project24: "معرض الشاي",
    galleryTitle: "معرض الصور",
    

    contact_info: "معلومات الاتصال",
    first_name: "الاسم الأول",
    last_name: "الكنية",
    email_address: "عنوان البريد الإلكتروني",
    mobile_number: "رقم الجوال",
    write_your_message: "اكتب رسالتك هنا",
    send: "إرسال",
    saudi_arabia: "المملكة العربية السعودية",
    contact_us: "اتصل بنا",
    modern_design_advertising: "التصميم الحديث للإعلان"
};
