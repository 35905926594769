const en = {
    contact_info: "Contact Information",
    first_name: "First Name",
    last_name: "Last Name",
    email_address: "Email Address",
    mobile_number: "Mobile Number",
    write_your_message: "Write Your Message Here",
    send: "Send",
    saudi_arabia: "Saudi Arabia",
    contact_us: "Contact us",
    modern_design_advertising: "Modern Design Advertising"
  };
  
  export default en;
  